import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {config} from '@/config';
// import { HttpClientProps } from 'AppModels';

const baseURL = config.apiUrl;

type HttpClientProps = {
  url: string,
  method?: string,
  customHeader?: Object,
  params?: Object,
  data?: Object,
}

const httpClient = <ReturnType>(config: HttpClientProps) => {

  const { url, method, customHeader, params, data } = config;
  const accessToken = localStorage.jwt || sessionStorage.jwt;

  const baseAxiosConfig: AxiosRequestConfig = {
    baseURL,
    url,
    ...(customHeader ?? {
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    }),
    params,
  };

  const axiosConfig =
    method === 'get'
      ? {
          ...baseAxiosConfig,
          method: 'get',
        }
      : {
          ...baseAxiosConfig,
          method,
          data,
        };

  return new Promise<ReturnType>((resolve, reject) =>
    axios(axiosConfig)
      .then(response => resolve(response.data))
      .catch((e: AxiosError) => reject(e))
  );
};

export default httpClient;