const publicPathConfig = {
  login: () => '/auth/login',
  // register: () => '/auth/register',
  notfound: () => '/notfound',
};

const privatePathConfig = {
  home: () => '/',
  dashboard: () => '/dashboard',
  tourcategory: () => '/category',
  tourlist: () => '/tour/tour-list',
  addtour: () => '/tour/add-tour',
  edittourdata: (id?: string) =>
    `/tour/tour-list/edit-tour-data/${id ?? ':id'}`,
  edittourimage: (id?: string) =>
    `/tour/tour-list/edit-tour-image/${id ?? ':id'}`,
  warn: () => '/system/warn',
  discount: () => '/system/discount',
  tourssearch: () => '/booking/tour-search',
  bookingpage: () => '/booking/booking-page',
  report: () => '/report',
  paymentsettings: () => '/system/payment-settings',
  initProperty: () => '/hotel/property/init',
  editProperty: (id?: number) => `/hotel/property/edit/${id ?? ':id'}`,
  editPropertyImage: (id?: number) =>
    `/hotel/property/edit-image/${id ?? ':id'}`,
  propertyList: () => '/hotel/property/list',
  editRoom: (propertyId?: number, id?: number) =>
    `/hotel/${propertyId ?? ':propertyId'}/room/edit/${id ?? ':id'}`,
  editRoomImage: (propertyId?: number, id?: number) =>
    `/hotel/${propertyId ?? ':propertyId'}/room/edit-image/${id ?? ':id'}`,
  roomList: (id?: number) => `/hotel/${id ?? ':id'}/room/list`,
  addRoom: (propertyId?: number) =>
    `/hotel/${propertyId ?? ':propertyId'}/room/add-room`,
  hotelBookingList: () => '/hotel/report/bookings',
};

export const privatePaths = Object.values(privatePathConfig).map(item =>
  item()
);

const pathsMap = {
  ...publicPathConfig,
  ...privatePathConfig,
};

export type PathsMap = typeof pathsMap;

export const getPath = <TRoute extends keyof PathsMap>(
  route: TRoute,
  ...params: Parameters<PathsMap[TRoute]>
) => {
  const pathCb: (...args: any[]) => string = pathsMap[route];
  return pathCb(...params);
};
