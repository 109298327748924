import { action, observable } from 'mobx'
import { IOption } from 'AppModels';

export class TourCategoryStore{
    @observable tourCategory : IOption[] = []
    @action setTourCategory = (arr : IOption[]) => {
      this.tourCategory = arr
    }
}

export default new TourCategoryStore();

