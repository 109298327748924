import { lazy } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { getPath, privatePaths } from 'router-paths';
import NotFoundPage from '@pages/NotFoundPage';
import LoginPage from '@pages/LoginPage';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import ProtectedRoute from '@routes/ProtectedRoute';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { ENDPOINT } from '@/config';

const history = createBrowserHistory();
const MainRoutes = lazy(() => import('@routes/MainRoutes'));
NProgress.configure({ showSpinner: false });

axios.defaults.timeout = 30000;
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    NProgress.start();
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
    NProgress.done();
  }
);
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    NProgress.done();
    if (
      response.config.url === ENDPOINT.LOGIN ||
      response.config.url === ENDPOINT.GET_ALL_WARN
    ) {
      return response;
    }
    if (response.data.code === 0) {
      return response.data;
    } else {
      const e = response.data.message;
      message.error(e.length > 200 ? 'Có lỗi xảy ra' : e);
    }
    // return response;
  },
  (error: AxiosError) => {
    const { response, code } = error;
    NProgress.done();
    if (response?.status === 401) {
      void message.error('Token expired!');
      localStorage.clear();
      history.push(getPath('login'));
    }
    if (response?.status === 403) {
      void message.error('No permission!');
      return;
    }
    if (response?.status === 408 || code === 'ECONNABORTED') {
      void message.error('Request timeout!');
      history.push(getPath('login'));
    }
    if (response?.status === 404 || code === 'ERR_BAD_REQUEST') {
      // history.push(getPath('notfound'));
      void message.error('Bad request!');
    }
    return Promise.reject(error);
  }
);

const AppRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={getPath('login')} component={LoginPage} />
        <ProtectedRoute exact path={privatePaths} component={MainRoutes} />
        <Route path={'*'} component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
